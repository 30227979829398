@use '@styles/_partials' as *;

.heroContainer {
  position: relative;
}

.headingContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  margin: auto;
  z-index: 99;

  @include respond-to($lg) {
    top: 50px;
  }

  h1 {
    @include respond-to($lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 1050px;
      margin: 0 auto;
    }
    @include respond-to($xl) {
      min-height: 300px;
    }
  }

  span {
    display: block;
    text-transform: uppercase;
    font-style: italic;
  }

  .topheading {
    background-color: $white;
    display: inline-block;
    transform: skew(-15deg);
    padding-left: pxToRem(2px);
    padding-right: pxToRem(5px);

    @include respond-to($md) {
      padding-left: pxToRem(4px);
      padding-right: pxToRem(8px);
    }

    @include respond-to($lg) {
      padding-right: pxToRem(12px);
    }

    span {
      color: $black;
      margin: 0;
      transform: skew(15deg);
      @include font-size(18px);
      @include line-height(24);
      @include letter-spacing(0);

      @include respond-to($md) {
        @include font-size(26px);
        @include line-height(32px);
      }

      @include respond-to($lg) {
        font-size: pxToRem(31px);
        letter-spacing: pxToRem(-0.4px);
        line-height: pxToRem(42px);
        @include font-size(31px);
        @include line-height(42px);
        @include letter-spacing(-0.4px);
      }
    }
  }

  .heading {
    color: $ltGreen;
    @include font-size(44px);
    @include line-height(44px);
    @include letter-spacing(-0.4px);

    @include respond-to($md) {
      @include font-size(74px);
      @include line-height(76px);
      @include letter-spacing(-0.7px);
    }

    @include respond-to($lg) {
      @include font-size(96px);
      @include line-height(102px);
      @include letter-spacing(-1px);
    }
  }

  .subheading {
    @include font-size(24px);
    @include line-height(25px);
    @include letter-spacing(0);

    @include respond-to($md) {
      @include font-size(32px);
      @include line-height(33px);
    }

    @include respond-to($lg) {
      @include font-size(56px);
      @include line-height(60px);
      @include letter-spacing(-0.4px);
    }
  }
}

.backgroundImage > img {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  height: 566px;
  object-fit: cover;
  z-index: 0;

  @include respond-to($md) {
    height: 700px;
    margin-top: 50px;
  }

  @include respond-to($lg) {
    height: 810px;
    object-fit: contain;
    margin-top: 80px;
  }

  @include respond-to($xl) {
    margin-top: 160px;
  }
}
